/* eslint-disable no-param-reassign */
import { Card } from 'antd';
import React, { useRef } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import { ItemTypes } from './Constants';

const style = {
  cursor: 'move',
};

const cardAdd = {
  height: '300px',
  background: '#dff0d8',
  border: '1px dotted #3c763d',
  margin: '15px 0',
  color: '#3c763d',
  fontSize: '50px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  textAlign: 'center',
};

const ItemContainer = ({ id, component, index, changeOrders, isLocked }) => {
  const ref = useRef(null);

  const [{ handlerId, isOver }, drop] = useDrop({
    accept: ItemTypes.CARD,

    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
        isOver: !!monitor.isOver(),
      };
    },

    drop(sendItem) {
      changeOrders({ sendItem, item: { id, column: index } });
    },
  });

  const [{ isDragging }, drag] = useDrag({
    type: ItemTypes.CARD,
    item: () => {
      return { id, column: index, isLocked };
    },
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging(),
    }),
  });

  const opacity = isDragging ? 'none' : 'block';
  const cursor = isLocked ? 'unset' : 'move';

  if (isLocked) {
    drop(ref);
    drag(null);
  } else {
    drag(drop(ref));
  }

  return (
    <div ref={ref} style={{ ...style, display: opacity, cursor }} data-handler-id={handlerId}>
      {isOver && opacity !== 'none' && <Card style={{ ...cardAdd }}>Soltar Aquí</Card>}
      {component}
    </div>
  );
};

export default ItemContainer;
