const mainRoutes = {
  timesheet: '/timesheet',
  tasks: '/tasks',
};

const Routes = {
  timesheet: {
    index: `${mainRoutes.timesheet}/`,
  },
  tasks: {
    home: `${mainRoutes.tasks}/`,
  },
};

export default Routes;
