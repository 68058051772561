import React, { useState } from 'react';
import { DndProvider } from 'react-dnd';
import { Col, Row, Card } from 'antd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import ItemContainer from './components/ItemContainer';
import NewTaskForm from './components/NewTaskForm';

import '../../assets/styles/tasks.css';

const Tasks = () => {
  const [dashboardOrder, setDashboardOrder] = useState([
    [{ title: 'Titulo', description: 'Descripcion' }, -1],
    [-1],
    [-1],
    [-1],
  ]);
  const changeOrders = (props) => {
    const dashboardOrderTemp = [...dashboardOrder];
    const cord1 = props.sendItem;
    const cord2 = props.item;
    cord1.column === props.item.column && cord2.id--;
    const item = dashboardOrderTemp[cord1.column]?.splice(cord1.id, 1);
    dashboardOrderTemp[cord2.column]?.splice(cord2.id, 0, ...item);
    setDashboardOrder(dashboardOrderTemp);
  };

  const newTask = (opc, taskData) => {
    const dashboardOrderTemp = [...dashboardOrder];
    dashboardOrderTemp[opc].splice(0, 0, taskData);
    setDashboardOrder(dashboardOrderTemp);
  };

  const setTitle = (opc) => {
    switch (opc) {
      case 0:
        return (
          <>
            <h2>To do</h2>
            <NewTaskForm onClick={(taskData) => newTask(opc, taskData)} />
          </>
        );
      case 1:
        return (
          <>
            <h2>In progress</h2>
            <NewTaskForm onClick={(taskData) => newTask(opc, taskData)} />
          </>
        );
      case 2:
        return (
          <>
            <h2>Blocked</h2>
            <NewTaskForm onClick={(taskData) => newTask(opc, taskData)} />
          </>
        );
      case 3:
        return (
          <>
            <h2>To verify</h2>
            <NewTaskForm onClick={(taskData) => newTask(opc, taskData)} />
          </>
        );
      default:
        return 'Error';
    }
  };

  return (
    <div>
      <div className="cards_container">
        <Row gutter={[32, 16]}>
          <DndProvider backend={HTML5Backend}>
            {dashboardOrder?.map((indexDash, index1) => (
              <Col key={index1} xs={24} sm={12} md={12} lg={6}>
                <div>{setTitle(index1)}</div>
                {indexDash?.map((order, index2) => (
                  <>
                    {order !== -1 ? (
                      <ItemContainer
                        key={index2}
                        index={index1}
                        id={index2}
                        changeOrders={changeOrders}
                        component={
                          <Card title={order?.title} className="card-dashboard">
                            {order?.description}
                          </Card>
                        }
                      />
                    ) : (
                      <ItemContainer
                        key={index2}
                        index={index1}
                        id={index2}
                        isLocked
                        changeOrders={changeOrders}
                        component={<div style={{ height: '250px', opacity: '0' }} />}
                      />
                    )}
                  </>
                ))}
              </Col>
            ))}
          </DndProvider>
        </Row>
      </div>
    </div>
  );
};

export default Tasks;
