import React from 'react';
import { Modal } from 'antd';

const IModal = ({ showModal, children, ...props }) => {
  return (
    <Modal centered open={showModal} {...props}>
      {children}
    </Modal>
  );
};

export default IModal;
