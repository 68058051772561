import React from 'react';
import { Card, Typography, Avatar, Select } from 'antd';
import { CheckSquareOutlined, MenuOutlined, UserOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';
import '../../../../../assets/styles/linkedIssues.css';

const LinkedIssues = ({ issueCode, issueDescription, users, ...props }) => {
  const { Text } = Typography;
  const handleChange = (value) => {
    console.log(`selected ${value}`);
  };
  return (
    <Card>
      <div className="linkedIssues-container">
        <div className="issue-info">
          <CheckSquareOutlined />
          <Text delete strong style={{ color: 'blue' }}>
            {issueCode}
          </Text>
          <Text strong>{issueDescription}</Text>
        </div>
        <div className="user-info">
          <MenuOutlined />
          <Avatar size="small" icon={<UserOutlined />} />
          <Select
            defaultValue="lucy"
            style={{ width: 120 }}
            onChange={handleChange}
            options={users}
          />
        </div>
      </div>
    </Card>
  );
};

LinkedIssues.propTypes = {
  issueCode: PropTypes.string,
  issueDescription: PropTypes.string,
  users: PropTypes.array,
};

LinkedIssues.defaultProps = {
  issueCode: 'WIN-21',
  issueDescription: 'Configurar Husky Hooks (pre commits messages)',
  users: [
    { value: 'jack', label: 'Jack' },
    { value: 'lucy', label: 'Lucy' },
    { value: 'Yiminghe', label: 'yiminghe' },
    { value: 'disabled', label: 'Disabled', disabled: true },
  ]
};

export default LinkedIssues;
