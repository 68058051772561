import React, { useState } from 'react';
import IModal from '../../../../components/IModal';
import { Typography, Breadcrumb } from 'antd';
import {
  NotificationOutlined,
  EyeFilled,
  LikeOutlined,
  ShareAltOutlined,
  EllipsisOutlined,
  CheckSquareOutlined,
} from '@ant-design/icons';
import TaskBody from './components/TaskBody';
import '../../../../assets/styles/newTask.css';

const ICON_SIZE = 18;

const NewTaskForm = ({ onClick }) => {
  const [showModal, setShowModal] = useState(false);
  const { Text } = Typography;
  return (
    <div className="newTask-container">
      <button onClick={() => setShowModal(true)}>New Task</button>
      {showModal && (
        <IModal
          showModal={showModal}
          onCancel={() => setShowModal(false)}
          title={
            <div className="modal-header">
              <Breadcrumb>
                <Breadcrumb.Item>WIN-26</Breadcrumb.Item>
                <Breadcrumb.Item>
                  <CheckSquareOutlined />
                  <a href="">WIN-28</a>
                </Breadcrumb.Item>
              </Breadcrumb>
              <div className="header-options">
                <div className="d-flex">
                  <NotificationOutlined style={{ fontSize: ICON_SIZE }} />
                  <Text strong>Give feedback</Text>
                </div>
                <div className="d-flex">
                  <EyeFilled style={{ fontSize: ICON_SIZE }} />
                  <Text strong>1</Text>
                </div>
                <div className="d-flex">
                  {/* <LikeFilled /> */}
                  <LikeOutlined style={{ fontSize: ICON_SIZE }} />
                </div>
                <div className="d-flex">
                  <ShareAltOutlined style={{ fontSize: ICON_SIZE }} />
                </div>
                <div className="d-flex">
                  <EllipsisOutlined style={{ fontSize: ICON_SIZE }} />
                </div>
              </div>
            </div>
          }
          footer={null}
          width={1200}
        >
          <TaskBody setShowModal={setShowModal} onClick={onClick} />
        </IModal>
      )}
    </div>
  );
};

export default NewTaskForm;
