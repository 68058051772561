import React from 'react';
import { Formik } from 'formik';
import {
  Form,
  Button,
  Typography,
  Dropdown,
  Badge,
  Alert,
  Progress,
  Avatar,
  Collapse,
  Select,
} from 'antd';
import {
  LinkOutlined,
  DiffOutlined,
  EllipsisOutlined,
  PaperClipOutlined,
  FilterOutlined,
  QuestionCircleFilled,
  BranchesOutlined,
  PlusOutlined,
  DownOutlined,
  SisternodeOutlined,
  SettingOutlined,
} from '@ant-design/icons';
import PropTypes from 'prop-types';
import LinkedIssues from './LinkedIssues';
import UserComment from './UserComment';
import ILabel from '../../../../../components/ILabel';
import TASK_SCHEMA from '../constants';
import '../../../../../assets/styles/taskBody.css';

const ICON_SIZE = 18;

const TaskBody = ({
  setShowModal,
  onClick,
  taskTitle,
  hours,
  createdAt,
  updatedAt,
  taskDescription,
  linkedCode,
  labels,
  items,
  backlogOptions,
  assignee,
  reporter,
  epicLink,
  priority,
  ...props
}) => {
  const { Title, Text } = Typography;
  const { Panel } = Collapse;
  const handleChange = (value) => {
    console.log(`selected ${value}`);
  };
  return (
    <Formik
      initialValues={{
        title: '',
        description: '',
      }}
      onSubmit={(values, { resetForm }) => {
        onClick(values);
        resetForm({ values: '' });
        setShowModal(false);
      }}
      validationSchema={TASK_SCHEMA}
    >
      {({ errors, touched }) => (
        <Form>
          <div className="new-task-container">
            <div className="form-content">
              <Title level={3}>{taskTitle}</Title>
              <div className="button-actions">
                <Button>
                  <PaperClipOutlined style={{ fontSize: ICON_SIZE }} />
                  Attach
                </Button>
                <Button>
                  <DiffOutlined style={{ fontSize: ICON_SIZE }} />
                  Create subtask
                </Button>
                <Button>
                  <LinkOutlined style={{ fontSize: ICON_SIZE }} />
                  Link issue
                </Button>
                <Button>
                  <EllipsisOutlined style={{ fontSize: ICON_SIZE }} />
                </Button>
              </div>
              <div className="modal-content">
                <div className="paragraph-content">
                  <Text strong>Description</Text>
                  <Text>{taskDescription}</Text>
                  <Text>Estimación: {hours}</Text>
                  <div>
                    <span>1</span>
                    <Text code>{linkedCode}</Text>
                  </div>
                </div>
                <div className="linked-issues">
                  <div className='d-flex-space-between'>
                    <Text strong>Linked issues</Text>
                    <PlusOutlined />
                  </div>
                  <Text>is caused by</Text>
                </div>
                <LinkedIssues />
                <div className="activity-content">
                  <Text strong>Activity</Text>
                  <div className="activity-tabs">
                    <div className="d-flex show">
                      <Text strong>Show:</Text>
                      <div>
                        <Button>All</Button>
                        <Button>Comments</Button>
                        <Button>History</Button>
                        <Button>Work log</Button>
                      </div>
                    </div>
                    <Text strong>
                      Newest first <FilterOutlined />
                    </Text>
                  </div>
                </div>
                <div>
                  <UserComment />
                </div>
              </div>
            </div>
            <div className="backlog-details">
              <Select
                defaultValue="backlog"
                style={{ width: 120 }}
                onChange={handleChange}
                options={backlogOptions}
              />
              <Collapse className="collapse-details" defaultActiveKey={['1']}>
                <Panel className="panel-details" header="Details" key="1">
                  <ILabel label="Assignee">
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                      <div>
                        <Avatar
                          style={{
                            backgroundColor: 'orange',
                            verticalAlign: 'middle',
                            marginRight: 5,
                          }}
                          size="medium"
                        >
                          T
                        </Avatar>
                        <Text>{assignee}</Text>
                      </div>
                      <a>Assign to me</a>
                    </div>
                  </ILabel>
                  <ILabel label="Reporter">
                    <Avatar
                      style={{ backgroundColor: 'blue', verticalAlign: 'middle', marginRight: 5 }}
                      size="medium"
                    >
                      S
                    </Avatar>
                    <Text>{reporter}</Text>
                  </ILabel>
                  <Alert
                    message="Informational Notes"
                    description="Link this issue to your code by including issue keys when creating a branch, commit, or pull request bellow."
                    icon={<QuestionCircleFilled />}
                    showIcon
                  />
                  <ILabel label="Development">
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                      <a className="three-cols-grid ">
                        <BranchesOutlined color="blue" />
                        <span>Create branch</span>
                      </a>

                      <Dropdown menu={{ items }}>
                        <a onClick={(e) => e.preventDefault()} className="three-cols-grid ">
                          <SisternodeOutlined />
                          <span>Create commit</span>
                          <DownOutlined />
                        </a>
                      </Dropdown>
                    </div>
                  </ILabel>
                  <ILabel label="Releases">
                    <Dropdown menu={{ items }}>
                      <a onClick={(e) => e.preventDefault()} className="three-cols-grid ">
                        <PlusOutlined />
                        <span>Add feature flag</span>
                        <DownOutlined />
                      </a>
                    </Dropdown>
                  </ILabel>
                  <ILabel label="Labels">
                    <Text>{labels}</Text>
                  </ILabel>
                  <ILabel label="Original estimate">
                    <Badge
                      className="site-badge-count-109"
                      style={{ backgroundColor: '#E6E6E6', color: 'black' }}
                      count={'2h'}
                    />
                  </ILabel>
                  <ILabel label="Time tracking">
                    <Progress percent={30} />
                  </ILabel>
                  <ILabel label="Epic Link">
                    <Text mark>{epicLink}</Text>
                  </ILabel>
                  <ILabel label="Priority">
                    <Text>{priority}</Text>
                  </ILabel>
                </Panel>
              </Collapse>
              <Collapse>
                <Panel header="More fields" key="1">
                  <Text>Aqui deberia haber algo mas</Text>
                </Panel>
              </Collapse>
              <div
                style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}
              >
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <Text>{createdAt}</Text>
                  <Text>{updatedAt}</Text>
                </div>
                <div>
                  <SettingOutlined />
                  <Text style={{ marginLeft: '5px' }}>Configure</Text>
                </div>
              </div>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};

TaskBody.propTypes = {
  taskTitle: PropTypes.string,
  hours: PropTypes.string,
  taskDescription: PropTypes.string,
  linkedCode: PropTypes.string,
  backlogOptions: PropTypes.array,
  asignee: PropTypes.string,
  reporter: PropTypes.string,
  labels: PropTypes.string,
  items: PropTypes.array,
  epicLink: PropTypes.string,
  priority: PropTypes.string,
  createdAt: PropTypes.string,
  updatedAt: PropTypes.string,
};

TaskBody.defaultProps = {
  taskTitle: 'ESLINT pre commit git +500 errors',
  hours: '3 hours',
  taskDescription: `Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem
  Ipsum has been the industry's standard dummy text ever since the 1500s, when an
  unknown printer took a galley of type and scrambled it to make a type specimen
  book. It has survived not only five centuries, but also the leap into electronic
  typesetting, remaining essentially unchanged. It was popularised in the 1960s
  with the release of Letraset sheets containing Lorem Ipsum passages, and more
  recently with desktop publishing software like Aldus PageMaker including
  versions of Lorem Ipsum.`,
  linkedCode: 'npm run eslint --ext .js, .jsx ./src',
  backlogOptions: [
    { value: 'backlog', label: 'Backlog' },
    { value: 'lucy', label: 'Lucy' },
    { value: 'Yiminghe', label: 'yiminghe' },
    { value: 'disabled', label: 'Disabled', disabled: true },
  ],
  assignee: 'Unassigned',
  reporter: 'STEVE JAMES FONCY ARIAS ARIAS',
  labels: 'None',
  items: [
    {
      key: '1',
      label: (
        <a target="_blank" rel="noopener noreferrer" href="https://www.antgroup.com">
          1st menu item
        </a>
      ),
    },
    {
      key: '2',
      label: (
        <a target="_blank" rel="noopener noreferrer" href="https://www.antgroup.com">
          2nd menu item
        </a>
      ),
    },
  ],
  epicLink: 'Improve Code NNTP',
  priority: 'Medium',
  createdAt: 'Created December 25, 2021 at 7:00 PM',
  updatedAt: 'Updated August 10, 2022 at 11:26 PM',
};

export default TaskBody;
