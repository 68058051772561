import React from 'react';
import PropTypes from 'prop-types';
import { Typography, Avatar, Input } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import '../../../../../assets/styles/userComment.css';

const UserComment = ({ ...props }) => {
  const { Text } = Typography;
  return (
    <div className="userComment-container">
      <Avatar size="medium" icon={<UserOutlined />} />
      <div className="userComment-right-section">
        <Input placeholder="Add a comment..." />
        <div className="bottom-text">
          <Text strong>Pro tip:</Text>
          <Text>press</Text>
          <Text keyboard>M</Text>
          <Text>to comment</Text>
        </div>
      </div>
    </div>
  );
};

export default UserComment;
