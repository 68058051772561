import * as Yup from 'yup';

export const TIMESHEET_SCHEMA = Yup.object().shape({
  text: Yup.string().required('Required'),
  time: Yup.date().required('Required'),
  city: Yup.string().required('Required'),
  color: Yup.string().required('Required'),
});

export const headersExcel = [
  { label: 'Fecha', key: 'date' },
  { label: 'Usuario', key: 'user' },
  { label: 'Horas', key: 'hours' },
  { label: 'Tarea', key: 'task' },
];

export const dataExcel = {
  user: 'Steve Arias',
  hours: '3',
  task: 'Diseño del Login de la Pagina',
};

export const cityDefaultValues = [
  {
    value: 'Lima',
    label: 'Lima',
  },
  {
    value: 'Arequipa',
    label: 'Arequipa',
  },
  {
    value: 'Loreto',
    label: 'Loreto',
  },
];
export const colorDefaultValues = [
  {
    value: 'Blue',
    label: 'Blue',
  },
  {
    value: 'Red',
    label: 'Red',
  },
  {
    value: 'Black',
    label: 'Black',
  },
];
