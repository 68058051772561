import React from 'react';
import { Typography } from 'antd';
import PropTypes from 'prop-types';
import '../../assets/styles/ilabel.css';

const ILabel = ({ label, children, ...props }) => {
  const { Text } = Typography;
  return (
    <div className="label-container">
      <div>
        <Text strong {...props}>
          {label}
        </Text>
      </div>
      {children}
    </div>
  );
};

ILabel.propTypes = {
  label: PropTypes.string,
  children: React.Children,
};

ILabel.defaultProps = {
  label: 'Label',
  children: null,
};

export default ILabel;
