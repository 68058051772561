import React, { useState } from 'react';
import { Calendar } from 'antd';
import { Formik, Form } from 'formik';
import { DatePicker, Space, Select, Input } from 'antd';
import { CSVLink } from 'react-csv';
import {
  TIMESHEET_SCHEMA,
  headersExcel,
  cityDefaultValues,
  dataExcel,
  colorDefaultValues,
} from './constants';
import IModal from '../../components/IModal';

import './../../assets/styles/timesheet.css';

const TimeSheet = () => {
  const [showModal, setShowModal] = useState(false);
  const [daySelected, setDaySelected] = useState(Date.now());
  const [dateSelected, setDateSelected] = useState(new Date());
  const [excelData, setExcelData] = useState([]);

  const getExcelName = () => {
    const newDate = dateSelected;
    const month = newDate.getMonth() + 1;
    const year = newDate.getFullYear();

    return `${month}_${year}_ARIAS_STEVE`;
  };

  const getMonthReports = () => {
    const newDate = dateSelected;
    const month = newDate.getMonth() + 1;
    const year = newDate.getFullYear();
    const dateFormat = newDate.toLocaleDateString('es-PE');

    if (new Date() < newDate) {
      setExcelData([]);
    } else {
      let date = new Date(year, month, 1);
      let days = [];
      while (date.getMonth() === month) {
        days.push(new Date(date));
        date.setDate(date.getDate() + 1);
      }
      let data = [];

      for (let i = 0; i < days.length; i++) {
        const rowObject = { ...dataExcel };
        rowObject.date = dateFormat;
        data.push(rowObject);
      }
      setExcelData(data);
    }
  };

  const openModalDay = (info) => {
    setShowModal(true);
    setDaySelected(info);
  };

  const handleSave = (values) => {
    setShowModal(false);
    alert(JSON.stringify(values));
  };

  return (
    <div className="sunny_timesheet">
      <CSVLink
        filename={`${getExcelName()}.csv`}
        data={excelData}
        headers={headersExcel}
        onClick={() => getMonthReports()}
      >
        Download me
      </CSVLink>
      ;
      <Calendar
        disabledDate={(date) => date > Date.now()}
        onSelect={(e) => openModalDay(e)}
        onPanelChange={(e) => {
          setDateSelected(e.toDate());
        }}
      />
      <Formik
        initialValues={{ text: '', date: '', city: '', color: '' }}
        validationSchema={TIMESHEET_SCHEMA}
      >
        {({
          isSubmitting,
          errors,
          touched,
          isValidating,
          values,
          handleSubmit,
          setSubmitting,
          validateOnBlur,
          handleChange,
          handleBlur,
        }) => (
          <Form>
            <IModal
              showModal={showModal}
              onOk={() => {
                handleSave(values);
              }}
              onCancel={() => setShowModal(false)}
              title={'Create Reminder'}
              className="sunny_timesheet-modal"
            >
              <div className="input_container">
                <label>Text</label>
                <Input
                  name="text"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.text}
                  placeholder="Please enter the text of the reminder"
                />
                {errors.text && touched.text ? <div>{errors.text}</div> : null}
              </div>
              <div className="input_container">
                <label>Time</label>
                <Space direction="vertical">
                  <DatePicker
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.date}
                    name="date"
                    placeholder="Select time"
                  />
                </Space>
                {errors.date && touched.date ? <div>{errors.date}</div> : null}
              </div>
              <div className="d-flex-between">
                <div className="input_container">
                  <label>City</label>
                  <Select
                    name="city"
                    defaultValue="Lima"
                    value={values.city}
                    style={{ width: '80%' }}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    options={cityDefaultValues}
                  />
                  {errors.city && touched.city ? <div>{errors.city}</div> : null}
                </div>
                <div className="input_container">
                  <label>Color</label>
                  <Select
                    name="color"
                    defaultValue="Blue"
                    value={values.color}
                    style={{ width: '80%' }}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    options={colorDefaultValues}
                  />
                  {errors.color && touched.color ? <div>{errors.color}</div> : null}
                </div>
              </div>
            </IModal>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default TimeSheet;
